<template>
  <v-dialog v-model="dialog" max-width="400px" @click:outside="resetForm()">
    <v-card>
      <v-card-title class="headline">Edit Bank Details</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-text-field
          label="Bank Sort Code"
          v-model="fields.bank_sort_code"
          outlined
        ></v-text-field>
        <v-text-field
          label="Bank Account Number"
          v-model="fields.bank_account_number"
          outlined
        ></v-text-field>
        <v-text-field
          label="IBAN Number"
          v-model="fields.iban_number"
          outlined
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          @click="saveForm()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        bank_sort_code: "",
        bank_account_number: "",
        iban_number: "",
      },
    };
  },

  methods: {
    openForm: function (bankSortCode, bankAccountNumber, ibanNumber) {
      this.fields.bank_sort_code = bankSortCode;
      this.fields.bank_account_number = bankAccountNumber;
      this.fields.iban_number = ibanNumber;
      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        bank_sort_code: "",
        bank_account_number: "",
        iban_number: "",
      };
    },

    saveForm: function () {
      const appId = this.$route.params.id;
      this.loading = true;

      this.$store
        .dispatch("drum/settings/saveSettings", {
          appId,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>