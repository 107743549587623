<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>Bank Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                small
                depressed
                @click="
                  $refs.bankDetailsForm.openForm(
                    bankSortCode,
                    bankAccountNumber,
                    ibanNumber
                  )
                "
              >
                <v-icon left>mdi-pencil</v-icon>Edit
              </v-btn>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Bank Sort Code</th>
                  <td>{{ bankSortCode }}</td>
                </tr>
                <tr>
                  <th>Bank Account Number</th>
                  <td>{{ bankAccountNumber }}</td>
                </tr>
                <tr>
                  <th>IBAN Number</th>
                  <td>{{ ibanNumber }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mt-5">
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>VAT Rate (%)</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small depressed @click="$refs.vatForm.openForm(vat)">
                <v-icon left>mdi-pencil</v-icon>Edit
              </v-btn>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>VAT Rate (%)</th>
                  <td>{{ vat }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <BankDetailsForm ref="bankDetailsForm" />
      <VatForm ref="vatForm" />
    </v-container>
  </div>
</template>

<script>
import BankDetailsForm from "./components/BankDetailsForm";
import VatForm from "./components/VatForm";

export default {
  components: {
    VatForm,
    BankDetailsForm,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Settings",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    bankSortCode() {
      return this.$store.state.drum.settings["bankSortCode"];
    },
    bankAccountNumber() {
      return this.$store.state.drum.settings["bankAccountNumber"];
    },
    ibanNumber() {
      return this.$store.state.drum.settings["ibanNumber"];
    },
    vat() {
      return this.$store.state.drum.settings["vat"];
    },
  },
};
</script>
